@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';

.kim-image {
    width: 8rem;
}

.page {
    width: 100%;
}
.hero {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    color: $color-primary-text;
    padding: 2rem;
    text-align: center;
    &__header {
        color: $color-SJLazerGold;
        text-transform: uppercase;
    }
    &__quote {
        font-size: 1.25rem;
        font-weight: 600;
        color: $color-primary-whiteGlobal;
    }
}

.about {
    color: $color-primary-whiteGlobal;
    margin: 2rem;
    text-align: center;
    &__section {
        font-size: 1.25rem;
        text-transform: uppercase;
        text-shadow: 1px 1px #e49fc7;
    }
    &__text {
        color: $color-primary-whiteGlobal;
        font-size: 1rem;
    }
}

.video {
    justify-self: center;
    width: 50%;
    max-width: 1200px;
    box-shadow: 20px 20px 40px -8px rgb(19, 25, 39);
    
}

.updatecontainer {
    width: 100%;
}
