@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';
@import '../../Styles/partials/global';

.about {
    margin: 2rem;
    text-align: center;
    &__section {
        font-size: 1.25rem;
        text-transform: uppercase;
        text-shadow: 1px 1px #e49fc7;
    }
    &__text {
        color: $color-primary-whiteGlobal;
        font-size: 1rem;
    }
}
.about-container {
    background-color: rgba(255, 218, 238, 0.5);
    border-radius: 20px;
    box-shadow: 20px 20px 40px -8px rgba(35,41,64,1);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    margin:2rem;
}

.about-us-container {
    padding: 1rem;
}