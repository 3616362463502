@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';

a {
    color: $color-primary-elements;
    text-transform:uppercase;
    font-weight: 600;
    text-decoration: none;
}
.container__header {
    justify-content: space-between;
    text-align:start;
    @include tablet {@include desktop {
        display: flex;
    }
}}

.header__logo {
    justify-content: left;
    width: 20rem;
    padding: 2rem;
}

.nav  {
    margin: 0;
    justify-content:flex-end;
    padding:0rem 2rem 1rem 2rem;
    list-style: none;
    color: $color-SJLazerGold;
    display: flex;
    &__link-home{
        @include link;
        padding: 1rem;
    }
    &__link-about{
        @include link;
        padding: 1rem;
    }
    &__link-portfolio{
        @include link;
        padding: 1rem;
    }
    &__link-contact{
        @include link;
        padding: 1rem;
    }
}