@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';
@import '../../Styles/partials/global';

.footer__logo {
    width: 10rem;
    padding: 2rem;
}

.container__footer {
    display: flex-end;
    text-align: center;
    position: relative;
}

.nav {
    padding:1rem 2rem 1rem 2rem;
    list-style: none;
    color: $color-SJLazerGold;
    display: flex;
    justify-content: center;
}
.nav__icon {
    padding: 1rem;
    max-width: 5rem;
    max-height: 5rem;
}

.footer__copyright {
    color: $color-SJLazerGold;
    padding-bottom: 1rem;
}