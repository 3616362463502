@mixin tablet(){
    @media screen and (min-width: 768px){
        @content
    }
};

@mixin desktop(){
    @media screen and (min-width: 1024px){
        @content
    }
};

@mixin link(){
    & {color: $color-primary-elements}
    &:visited {
        color: $color-primary-elements
    }
    &:hover {
    font-size: 1.15rem;
    font-weight: 600;
    color:$color-SJPink;
    }
    &:active{
        color: $color-SJLazerGold;
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration: 2px $color-SJLazerGold underline;
    }
    }

    @mixin cover-background {
        background: rgb(246,83,166);
background: radial-gradient(circle, rgb(141, 35, 90) 0%, rgba(35,41,64,1) 65%);
        background-repeat: repeat;
        background-size:cover;
        background-position: center;
    }