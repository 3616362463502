@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';
@import '../../Styles/partials/global';

.updateform {
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    color: $color-backGround-nav;
    background-color: rgba($color-SJPink, .6);
    border-radius: 6px;
    width: 60%;
    &__header {
        width: 30%;
        margin: 0;
    }
    &__field {
        border-radius: 4px;
        padding: .5rem;
    }
    &__button {
        background-color: $color-backGround-nav;
        color: $color-primary-whiteGlobal;
        padding: .5rem;
    }
    &__form {
        margin: 0;
    }
}

