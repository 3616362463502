@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';
@import '../../Styles/partials/global';

.contact-container {
    background-color: rgba(255, 218, 238, 0.5);
    border-radius: 20px;
    box-shadow: 20px 20px 40px -8px rgba(35,41,64,1);
    display: flex;
    flex-direction: column;
   align-items: center;
    gap: 1.5rem;
    padding: 1rem;
    margin:2rem;
}
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    width: 100%;
    max-width: 500px;
  
    .form-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
  
      label {
        font-weight: 600;
        color: $color-primary-whiteGlobal;
      }
  
      input,
      textarea {
        padding: 0.8rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
        outline: none;
  
        &:focus {
          border-color: #007bff;
          box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
        }
      }
  
      textarea {
        resize: none;
      }
    }
  
    .contact-btn {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: $color-backGround-nav;
      background: $color-SJLazerGold;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
  
      &:hover {
        background: #0056b3;
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }
    }
  }

  .nav {
    padding:1rem 2rem 1rem 2rem;
    list-style: none;
    color: $color-SJLazerGold;
    display: flex;
    justify-content: center;
    &__icon {
        padding: 1rem;
        width: 5rem;
    }
}