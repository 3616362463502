// font-size Desktop and Tablet

$deskTab-fontSize-H1: 32px;
$deskTab-fontSize-H2: 24px;
$deskTab-fontSize-H3: 14px;
$deskTab-fontSize-H4: 12px;
$deskTab-fontSize-P1: 16px;
$deskTab-fontSize-P2: 14px;
$deskTab-fontSize-P3: 12px;

// line Height DeskTop and Tablet

$deskTab-lineHeight-H1: 40px;
$deskTab-lineHeight-H2: 32px;
$deskTab-lineHeight-H3: 22px;
$deskTab-lineHeight-H4: 18px;
$deskTab-lineHeight-P1: 28px;
$deskTab-lineHeight-P2: 22px;
$deskTab-lineHeight-P3: 18px;

//font-size Moblie

$mobile-fontSize-H1: 28px;
$mobile-fontSize-H2: 20px;
$mobile-fontSize-H3: 14px;
$mobile-fontSize-H4: 11px;
$mobile-fontSize-P1: 15px;
$mobile-fontSize-P2: 13px;
$mobile-fontSize-P3: 11px;

// line- heigt Mobile

$mobile-lineHeight-H1: 36px;
$mobile-lineHeight-H2: 28px;
$mobile-lineHeight-H3: 20px;
$mobile-lineHeight-H4: 16px;
$mobile-lineHeight-P1: 26px;
$mobile-lineHeight-P2: 20px;
$mobile-lineHeight-P3: 16px;

// Colors 
$color-primary-text: #080808d5;
$color-SJLazerGold:#C8B568;
$color-primary-elements: #ffdaee;
$color-primary-whiteGlobal: #FFFFFF;
$color-secondary-text: #5C667E;
$color-secondary-lining: #BDC5D5; 
$color-SJPink: #F653A6;
$color-backGround-nav: #232940;
$color-backGround-page: #F7F8F9;
