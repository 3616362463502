@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';

*, *::before, *::after {
    box-sizing: border-box;
}

body {
@include cover-background;
margin: 0;
padding: 0;
}

footer {
    margin-top: auto;
}