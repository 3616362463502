@import '../../Styles/partials/mixins';
@import '../../Styles/partials/variables';
@import '../../Styles/partials/global';

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 20px;
    @include tablet; @include desktop {
        flex-direction: row;
    }
    &__container {
        display: flex;
        flex-direction: column;
        background-color: #bdc5d583;
        font-size: .75rem;
        margin: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 6px 6px 20px -4px rgba(35,41,64,1);
        @include tablet; @include desktop {
            font-size: 1rem;
            width: 35%;
        }
        }
    
    &__text {
        color: $color-backGround-nav;
        margin: 1rem;
        font-size: 1rem;
    }
    &__image {
        margin:1rem;
        object-fit: contain;
        width: 12rem;
        height: 5rem;
    }
}

.card__header {
    color: $color-primary-elements;
    margin: 2rem;
    font-size: 1rem;
};

.card__title {
    color: transparent;
    margin: 0;
    font-size: x-small;
}